import { call, delay, put } from '@redux-saga/core/effects'
import { END } from '@redux-saga/core'

export const normalizedArray = (response: any) => response ? response : []

export const normalized = (response: any) => response ? response : {}

export const actionObject = (type: string, payload: any = null) => ({ type, payload })

export const paginate = (items: Array<any>, page_number: number = 1, page_size: number = 15) => {
  return items.slice((page_number - 1) * page_size, page_number * page_size);
}

export const scrollTo = (ref: any, offset = 0) => {
  window.scrollTo({ top: ref.offsetTop - offset, behavior: 'smooth' });
}

export const createMarkup = (text: any) => { return { __html: text }; }

export const validateFetch = ({ errors, data }: any) => {
  if (errors && !data) throw errors[0]
  if (typeof (data) == 'undefined') throw new Error('Cannot connect')

  return data
}

export function* showDialog(message: any, type = 'success', toast = 'SHOW_TOAST') {
  yield put(actionObject(toast, {
    status: 1,
    message,
    type
  }))

  yield delay(3000)

  yield put(actionObject(toast, {
    status: 2
  }))
}

export function* manageError(error: any, toast = 'SHOW_TOAST', loader = 'SHOW_LOADER') {
  yield put(actionObject(loader, false))
  yield call(showDialog, error, 'error', toast)
}

export const mapProps = async (store: any, action: any) => {
  store.dispatch(action)
  store.dispatch(END)
  await store.sagaTask.toPromise();
}

export const roundNumber = (number: any) => Math.round(number * 100) / 100

export const elementId = (id: string) => {
  const element: any = document.querySelector(id)
  return element?.id
}

export const parseDate = (currentDate: any) => {
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth() + 1
  const day = currentDate.getDate()

  return `${day}/${month}/${year}`
}

export const parseHour = (data: any) => {
  const date = new Date(data)
  let minutes: any = date.getMinutes()
  let hour: any = date.getHours()
  const setHour = hour === 0 ? 12 : hour
  hour = hour > 12 ? hour - 12 : setHour
  hour = hour < 10 ? `0${hour}` : hour
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const afternoon = date.getHours() > 11 ? 'PM' : 'AM'
  return `${hour}:${minutes} ${afternoon}`
}

export const buildSimpleArray = (key: string, array: any = []): Array<any> => {
  let newArray: any = []
  array.forEach((item: any, index: number) => {
    newArray[index] = item[key]
  })

  return newArray
}

export const sleep = (time: any) => {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export const buildRTF = (rtf: any, label: any, style?: any) => {

  if (typeof rtf?.value !== "string" && rtf) {
    let finalElement = `<div class='${style}'>`;

    const rtfValue = (typeof rtf === 'string') ? JSON.parse(rtf) : rtf

    for (const paragraph of rtfValue) {
      let element = `<${label}>`;
      const children = paragraph?.children || []
      for (const child of children) {
        const text = child?.text;
        let style = "";
        for (const childType in child) {
          if (child[childType] && childType === "italic")
            style += `font-style:${childType};`;
          if (child[childType] && childType === "underline")
            style += `text-decoration: ${childType};`;
          if (child[childType] && childType === "bold")
            style += `font-weight: ${childType};`;
        }
        element += `<span style='${style}'>${text}</span>`;
      }

      element += `</${label}>`;
      finalElement += element;
    }
    finalElement += `</div>`;
    return finalElement;
  }

  return "";
};

export const fixValues = (values: any) => {
  const keys = Object.keys(values)
  const newValues: any = []
  for (const key of keys) newValues.push({ key, value: values[key] })
  return newValues
}

export const concatAddress = (data: any) => {
  const address = `${data?.city || ''}, ${data?.state || ''}, ${data?.address || ''}`
  return address
}

export const uploadValues = [
  'FIRST_NAME',
  'LAST_NAME',
  'EMAIL',
  'PHONE',
  'PASSWORD',
  'COMPANY_ID'
]
export const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});
