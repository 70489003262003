import { Box, Paper, Table, TableContainer, TablePagination } from '@mui/material';
import { CustomTableBody, CustomTableHead, CustomToolbar } from './elements';
import { Order } from '@/utils';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';


const EnhancedTable: FC<any> = ({ title, header, values, page = 0, perPage = 0, onPageChange, totalItems = 0, onRowsPerPageChange }) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('calories');
  const [selected, setSelected] = useState<readonly string[]>([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = values.map((n: any) => n[header[0]?.id]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(header[0]?.id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, header[0]?.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (onPageChange) onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (onRowsPerPageChange) onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, maxWidth: '100%' }}>
        <CustomToolbar numSelected={selected.length} name={title} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750, width: '100%', maxWidth: '100%' }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <CustomTableHead
              header={header}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={values.length}
            />
            <CustomTableBody rows={values} page={page} header={header} rowsPerPage={perPage} order={order} orderBy={orderBy} selected={selected} handleClick={handleClick} />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems}
          rowsPerPage={perPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default EnhancedTable
