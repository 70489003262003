import { takeLatest, call, put, select } from 'redux-saga/effects'
import { actionObject, RestClient } from '@/utils'
import { setError, setLoaderShow } from '@/store/actions'
import { GET_USERS, GET_USERS_ASYNC, GET_USERS_TABLE, GET_USERS_TABLE_ASYNC, UPLOAD_CSV_FILE, UPLOAD_CSV_FILE_ASYNC } from './action-types'
import { getUser } from '../selectors'

function* getUsersAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/users`, 'GET', {}, auth?.user?.token)

    yield put(actionObject(GET_USERS_ASYNC, result?.users))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}

function* getUsersTableAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/users/table`, 'POST', payload, auth?.user?.token)

    yield put(actionObject(GET_USERS_TABLE_ASYNC, result))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}



function* uploadCSVFileAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/users/upload`, 'POST', payload, auth?.user?.token)

    yield put(actionObject(UPLOAD_CSV_FILE_ASYNC, result))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}
export function* watchGetUsers() {
  yield takeLatest(GET_USERS, getUsersAsync)
}
export function* watchGetUsersTable() {
  yield takeLatest(GET_USERS_TABLE, getUsersTableAsync)
}

export function* watchUploadCSVFile() {
  yield takeLatest(UPLOAD_CSV_FILE, uploadCSVFileAsync)
}
