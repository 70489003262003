import { RequestQuote } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { FC } from "react"

const ValidateButton: FC<any> = ({ onClick = () => { } }) => {
  return (
    <IconButton color="primary" aria-label="Validate payment" onClick={onClick}>
      <RequestQuote />
    </IconButton>
  )
}

export default ValidateButton
