import { Box, Button, Container } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import EnhancedTable from "../Table";
import { useDispatch, useSelector } from "react-redux";
import { getUsersTable } from "@/store/actions";
import header from "./header";
import { ConsumeLimit, UploadModal } from "./elements";
import { Add } from "@mui/icons-material";

const UserPage: FC = () => {
  const dispatch = useDispatch()
  const [firstRender, setFirstRender] = useState(false)
  const [open, setOpen] = useState(false)

  const { user: { table } } = useSelector((state: any) => state)

  useEffect(() => {
    if (firstRender) dispatch(getUsersTable({ page: 1, per_page: 5 }))
    if (!firstRender) setFirstRender(true)
    // eslint-disable-next-line
  }, [firstRender])


  const handleUsers = () => {
    const users = [...table.items || []].map((user: any) => {
      const consume = user?.subscriptionDetail?.consumes;
      const limit = user?.subscription?.subscriptionRate?.uses
      return { ...user, subscription: user?.subscription?.name, company: user?.company?.name, actions: <ConsumeLimit progress={(consume / limit) * 100} /> }
    })
    return users
  }

  const users = useMemo(handleUsers, [table?.items])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container component="main" sx={{ maxWidth: '100% !important' }} >
      <h2>USERS</h2>
      <Box>
        <Button variant="outlined" startIcon={<Add />} style={{ marginBottom: '20px' }} onClick={handleOpen}>Upload CSV</Button>
      </Box>
      <EnhancedTable header={header} values={users} page={Number(table?.page) - 1} totalItems={Number(table?.total_items)} onPageChange={(page: any) => { ; dispatch(getUsersTable({ per_page: table?.per_page, page: page + 1 })) }} onRowsPerPageChange={(per_page: any) => { dispatch(getUsersTable({ per_page, page: table?.page })) }} perPage={Number(table?.per_page)} pages={Number(table?.num_pages)} />
      {open && <UploadModal open={open} handleClose={handleClose} />}
    </Container>
  )
}

export default UserPage
