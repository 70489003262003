import { takeLatest, call, put } from 'redux-saga/effects'
import { actionObject, RestClient } from '@/utils'
import { setError, setLoaderShow } from '@/store/actions'
import { LOGIN_USER, LOGIN_USER_ASYNC } from './action-types'

function* loginAsync({ payload }: any): any {
  try {
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/login`, 'POST', payload, null)

    yield put(actionObject(LOGIN_USER_ASYNC, { ...result?.user || {}, token: result?.token }))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_USER, loginAsync)
}
