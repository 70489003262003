import { LinearProgressWithLabel } from "@/components/Shared"
import { Box } from "@mui/material"
import { FC } from "react"

const ConsumeLimit: FC<any> = ({ progress }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel variant="determinate" value={progress} />
    </Box>
  )
}

export default ConsumeLimit
