import React, { FC } from "react";
import { PrivateRouter, PublicRouter } from "./routers";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Loader } from "@/components";

const theme = createTheme();
const Router: FC = () => {
  const { auth: { isAuth }, intermitence: { showLoader } } = useSelector((state: any) => state)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isAuth ? <PrivateRouter /> : <PublicRouter />}
      {showLoader && <Loader />}
    </ThemeProvider>
  )
}

export default Router
