import { all, fork } from 'redux-saga/effects'
import { watchLogin } from './auth/saga'
import { watchGetUsers, watchGetUsersTable, watchUploadCSVFile } from './user/saga'
import { watchGetPaymentsTable, watchValidatePayment } from './payment/saga'
import { watchCreateCompany, watchGetCompanies } from './company/saga'

function* sagas() {
  yield all([
    fork(watchLogin),
    fork(watchGetUsers),
    fork(watchGetUsersTable),
    fork(watchGetPaymentsTable),
    fork(watchValidatePayment),
    fork(watchGetCompanies),
    fork(watchCreateCompany),
    fork(watchUploadCSVFile)
  ])
}

export default sagas
