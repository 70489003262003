
import createSagaMiddleware from 'redux-saga'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import reducer from './reducers'
import sagas from './sagas'
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from "redux-persist";

const createNoopStorage = () => {
  return {
    getItem(_key: any) {
      return Promise.resolve(null);
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value);
    },
    removeItem(_key: any) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined"
    ? createWebStorage("local")
    : createNoopStorage();
const persistConfig = {
  key: "easyai-backoffice",
  whitelist: ["auth"],
  blacklist: ['user', 'payment'],
  storage,
};
const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducer);

const store: any = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      inmutableCheck: false,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

store.__persistor = persistStore(store);
store["sagaTask"] = sagaMiddleware.run(sagas);

export default store
