import { takeLatest, call, put, select } from 'redux-saga/effects'
import { actionObject, RestClient } from '@/utils'
import { setError, setLoaderShow } from '@/store/actions'
import { CREATE_COMPANY, CREATE_COMPANY_ASYNC, GET_COMPANIES, GET_COMPANIES_ASYNC } from './action-types'
import { getUser } from '../selectors'

function* getCompaniesAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/companies`, 'GET', {}, auth?.user?.token)
    yield put(actionObject(GET_COMPANIES_ASYNC, result?.companies))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))
  }
}

function* createCompanyAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/companies`, 'POST', payload,  auth?.user?.token)
    yield put(actionObject(CREATE_COMPANY_ASYNC, result?.company))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}

export function* watchGetCompanies() {
  yield takeLatest(GET_COMPANIES, getCompaniesAsync)
}

export function* watchCreateCompany() {
  yield takeLatest(CREATE_COMPANY, createCompanyAsync)
}
