const header = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'user',
    disablePadding: true,
    label: 'User',
  },
  {
    id: 'paymentMethod',
    disablePadding: true,
    label: 'Payment Method',
  },
  {
    id: 'subscription',
    disablePadding: true,
    label: 'Subscription',
  },
  {
    id: 'valid',
    disablePadding: true,
    label: 'Valid Payment',
  },
  {
    id: 'creation_date',
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'Actions',
  },
]

export default header
