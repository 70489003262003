import { Container } from "@mui/material";
import { FC } from "react";

const PackagePage: FC = () => {
  return (
    <>
      <Container component="main" sx={{ maxWidth: '100% !important' }} >
        <h2>PACKAGES</h2>
      </Container>
    </>
  )
}

export default PackagePage
