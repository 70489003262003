import { AnyAction } from 'redux'
import { CREATE_COMPANY_ASYNC, GET_COMPANIES_ASYNC } from './action-types'

const initialState = {
  companies: [],
  company: {},
  table: {},
}

const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_COMPANIES_ASYNC:
      return { ...state, companies: payload }
    case CREATE_COMPANY_ASYNC:
      return { ...state, company: payload }
    default:
      return state
  }
}

export default reducer
