import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies } from "@/store/actions";
import header from "./header";
import EnhancedTable from "../Table";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { CreateModal } from "./elements";


const CompanyPage: FC = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const { company: { companies } } = useSelector((state: any) => state)

  useEffect(() => {
    if (firstRender) dispatch(getCompanies())
    if (!firstRender) setFirstRender(true)
    // eslint-disable-next-line
  }, [firstRender])

  const handleCompanies = () => {
    const companyList = companies.map((item: any) => {
      return {
        ...item,
        active: item.active ? 'Yes' : 'No',
        actions: <>
          <Button variant="outlined" startIcon={<EditIcon />}>Edit</Button>&nbsp;
          {item.active ? (
            <Button variant="outlined" startIcon={<ToggleOnIcon />}>Disable</Button>
          ) : (
            <Button variant="outlined" startIcon={<ToggleOffIcon />}>Enable</Button>
          )}
        </>
      }
    })
    return companyList
  }

  const companyList = useMemo(handleCompanies, [companies])
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Container component="main" sx={{ maxWidth: '100% !important' }} >
        <h2>COMPANIES</h2>
        <Button variant="outlined" startIcon={<AddIcon />} style={{ marginBottom: '20px' }} onClick={handleOpen}>Add</Button>
        <EnhancedTable
          header={header}
          values={companyList}
          totalItems={Number(companies?.length)}
        />
      </Container>
      {open && <CreateModal open={open} handleClose={handleClose} />}
    </>
  )
}

export default CompanyPage
