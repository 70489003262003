const header = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'FIRST NAME',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: true,
    label: 'LAST NAME',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'EMAIL',
  },
  {
    id: 'phone',
    numeric: true,
    disablePadding: false,
    label: 'PHONE NUMBER',
  },
  {
    id: 'subscription',
    numeric: true,
    disablePadding: false,
    label: 'SUBSCRIPTION',
  },
  {
    id: 'company',
    numeric: true,
    disablePadding: false,
    label: 'COMPANY',
  },
  {
    id: 'actions',
    disablePadding: false,
    label: 'USE STATS',
  },
]

export default header
