import axios from 'axios';
import { call } from 'redux-saga/effects';

const API_URL = process.env.REACT_APP_SERVER
const API_KEY = process.env.REACT_APP_APIKEY

function* RestClient(url: any, method = "GET", variables = {}, Authorization: any = null): any {
  try {
    const headers: any = {
      "Content-Type": "application/json",
      "Api-Key": API_KEY,
      "language": 'es'
    };
    if (Authorization) headers['Authorization'] = Authorization
    const response = yield call(axios, `${API_URL}/${url}`, {
      headers,
      method: method,
      data: variables,
    });

    return response.data;
  } catch (err: any) {
    throw new Error(JSON.stringify(err?.response?.data) || 'An error has ocurred');
  }
}

export default RestClient;
