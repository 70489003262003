import { AnyAction } from 'redux'
import { GET_USERS_ASYNC, GET_USERS_TABLE_ASYNC, UPLOAD_CSV_FILE_ASYNC } from './action-types'

const initialState = {
  users: [],
  user: {},
  table: {}
}

const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_USERS_ASYNC:
      return { ...state, users: payload }
    case GET_USERS_TABLE_ASYNC:
      return { ...state, table: payload }
    case UPLOAD_CSV_FILE_ASYNC:
      return { ...state, table: payload }
    default:
      return state
  }
}

export default reducer
