import { AnyAction } from 'redux'
import { LOGIN_USER_ASYNC, LOGOUT_USER } from './action-types'

const initialState = {
  user: {},
  isAuth: false,
  recover: false,
}

const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case LOGIN_USER_ASYNC:
      return { ...state, user: payload, isAuth: true }
    case LOGOUT_USER:
      return { ...state, user: {}, isAuth: false }
    default:
      return state
  }
}

export default reducer
