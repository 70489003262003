import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import EnhancedTable from "../Table";
import { useDispatch, useSelector } from "react-redux";
import header from "./header";
import { changePayment, getPaymentsTable, validatePayment } from "@/store/actions";
import { ValidateButton } from "./elements";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const PaymentPage: FC = () => {

  const dispatch = useDispatch()
  const [firstRender, setFirstRender] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<any>(null)

  const { payment: { table, indexPayment } } = useSelector((state: any) => state)

  useEffect(() => {
    if (firstRender) dispatch(getPaymentsTable({ page: 1, per_page: 5 }))
    if (!firstRender) setFirstRender(true)
    // eslint-disable-next-line
  }, [firstRender])


  const handlePayments = () => {
    const payments = [...table.items || []].map((payment: any) => {
      return { ...payment, subscription: payment?.subscription?.name, user: `${payment?.user?.firstName} ${payment?.user?.lastName}`, valid: payment?.valid ? 'Yes' : 'No', paymentMethod: payment?.paymentMethod?.name, creation_date: new Date(payment?.creation_date['$date']).toDateString(), actions: payment?.valid ? null : <ValidateButton onClick={() => { setShowModal(true); setSelectedPayment(payment) }} /> }
    })
    return payments
  }

  const payments = useMemo(handlePayments, [table.items])

  const validate = (id: any) => {
    dispatch(validatePayment(id))
    setSelectedPayment(null)
    setShowModal(false)
  }

  useEffect(() => {
    if (indexPayment !== null) {
      dispatch(changePayment(null))
      window.location.reload()
    }
    // eslint-disable-next-line
  }, [indexPayment])

  return (
    <>
      <Container component="main" sx={{ maxWidth: '100% !important' }}>
        <h2>PAYMENTS</h2>
        <EnhancedTable header={header} values={payments} page={Number(table?.page || 0) - 1} totalItems={Number(table?.total_items || 0)} onPageChange={(page: any) => { dispatch(getPaymentsTable({ per_page: table?.per_page, page: page + 1 })) }} onRowsPerPageChange={(per_page: any) => { dispatch(getPaymentsTable({ per_page, page: table?.page })) }} perPage={Number(table?.per_page || 0)} pages={Number(table?.num_pages || 0)} />
      </Container>
      <Modal
        open={showModal}
        onClose={() => setShowModal(!showModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{ marginBottom: '2rem' }} component='h2'>Validate Payment</Typography>
          {
            Object.keys(selectedPayment?.values || {})?.map((key: any, index: any) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <Typography component='p'>{key?.replace('_', ' ')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component='p'>{selectedPayment?.values[key]}</Typography>
                  </Grid>
                </Grid>
              )
            })
          }
          <Button variant="contained" sx={{ margin: '0 auto', display: 'block', marginTop: '2rem' }} onClick={() => validate(selectedPayment?.id)}>Validate</Button>
        </Box>
      </Modal>
    </>
  )
}

export default PaymentPage
