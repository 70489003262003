import { FC } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from "react-redux";
import { logoutUser } from "@/store/actions";
import { Link } from "react-router-dom";

const SubListItem: FC = () => {
  const dispatch = useDispatch()
  const logout = () => dispatch(logoutUser())
  return (
    <>
      <Link to='/'>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </Link>
    </>
  )
}

export default SubListItem
