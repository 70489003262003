import { FC, useEffect, useState } from "react"
import { Box, Typography, Modal, Button, Grid, TextField } from '@mui/material';
import { modalStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { createCompany } from "@/store/actions";
const CreateModal: FC<any> = ({ open, handleClose }) => {


  const [errors, setErrors] = useState<any>({})
  const { intermitence: { error }, company: { company } } = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const checkInput = (event: any) => {
    const value = event?.currentTarget?.value
    const name = event?.currentTarget?.name
    const selection: any = {
      'name': null,
      'contactFirstName': null,
      'contactLastName': null,
      'contactEmail': null
    }
    const check = (selection[name]) ? !selection[name]?.test(value) : false
    if (check) return setErrors((old: any) => ({ ...old, [name]: true }))
    return setErrors((old: any) => ({ ...old, [name]: false }))
  }

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    const form = new FormData(event?.target)
    const values = form.entries();
    const formValues: any = {}
    for (const val of values) formValues[val[0]] = val[1]
    dispatch(createCompany(formValues))
  };

  useEffect(() => {
    if (Object.keys(company || {})?.length) window.location.reload()
  }, [company])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Company
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="name"
                label="Company Name"
                name="name"
                autoComplete="name"
                error={errors?.email}
                onChange={checkInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="contactFirstName"
                label="Contact First Name"
                type="text"
                id="contactFirstName"
                autoComplete="contactFirstName"
                error={errors?.password}
                onChange={checkInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="contactLastName"
                label="Contact Last Name"
                type="text"
                id="contactLastName"
                autoComplete="contactLastName"
                error={errors?.password}
                onChange={checkInput}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="contactEmail"
                label="Contact Email Name"
                type="text"
                id="contactEmail"
                autoComplete="contactEmail"
                error={errors?.password}
                onChange={checkInput}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!(Object.keys(errors)?.length > 3) || errors?.name || errors?.contactFirstName || errors?.contactLastName || errors?.contactEmail}
          >
            Create
          </Button>
          {!!error && <Typography component="p" color={'error'}>
            {error || ''}
          </Typography>}
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateModal
