import { TableBody, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { CustomBodyInterface } from "./interface";

const CustomTableBody: FC<CustomBodyInterface> = ({ rows, selected, header }) => {

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0

  return (
    <TableBody>
      {rows?.map((row: any, index: any) => {
        const isItemSelected = isSelected(header[0]?.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={`${row[header[0]?.id]}${index}`}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              align="center"
            >
              {row[header[0]?.id]}
            </TableCell>
            {header.slice(1, header?.length).map((rowValue: any, index: any) => {
              return <TableCell key={index} align="center">{row[rowValue?.id]}</TableCell>
            })}
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  )

}

export default CustomTableBody
