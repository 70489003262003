import { loginUser } from "@/store/actions";
import { emailRegex } from "@/utils";
import { Avatar, Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography } from "@mui/material"
import { FC, useState } from "react"
import { useDispatch, useSelector } from "react-redux";


const LoginPage: FC = () => {

  const [errors, setErrors] = useState<any>({})
  const dispatch = useDispatch()
  const { intermitence: { error } } = useSelector((state: any) => state)

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    const form = new FormData(event?.target)
    const values = form.entries();
    const formValues: any = {}
    for (const val of values) formValues[val[0]] = val[1]
    dispatch(loginUser(formValues))
  };

  const checkInput = (event: any) => {
    const value = event?.currentTarget?.value
    const name = event?.currentTarget?.name
    const selection: any = {
      'email': emailRegex,
      'password': null,
    }
    const check = (selection[name]) ? !selection[name]?.test(value) : false
    if (check) return setErrors((old: any) => ({ ...old, [name]: true }))
    return setErrors((old: any) => ({ ...old, [name]: false }))
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errors?.email}
                onChange={checkInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                error={errors?.password}
                onChange={checkInput}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!(Object.keys(errors)?.length > 1) || errors?.email || errors?.password}
          >
            Sign In
          </Button>
          {!!error && <Typography component="p" color={'error'}>
            {error || ''}
          </Typography>}
        </Box>
      </Box>
    </Container>
  )
}

export default LoginPage
