import { takeLatest, call, put, select } from 'redux-saga/effects'
import { actionObject, RestClient } from '@/utils'
import { setError, setLoaderShow } from '@/store/actions'
import { GET_PAYMENTS_TABLE, GET_PAYMENTS_TABLE_ASYNC, VALIDATE_PAYMENT, VALIDATE_PAYMENT_ASYNC } from './action-types'
import { getPayments, getUser } from '../selectors'


function* getPaymentsTableAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    yield put(setLoaderShow(true))
    const { result } = yield call(RestClient, `admin/payments/table`, 'POST', payload, auth?.user?.token)

    yield put(actionObject(GET_PAYMENTS_TABLE_ASYNC, result))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}

function* validatePaymentAsync({ payload }: any): any {
  try {
    const auth = yield select(getUser)
    const payment = yield select(getPayments)

    yield put(setLoaderShow(true))

    const { result } = yield call(RestClient, `admin/payments/action`, 'POST', { id: payload }, auth?.user?.token)
    const table = { ...payment?.table }
    const indexPayment = table?.items?.findIndex((pay: any) => pay?.id === result?.payment?.id)

    const items = [...table.items]
    if (indexPayment >= 0) items.splice(indexPayment, 1, result?.payment)
    yield put(actionObject(GET_PAYMENTS_TABLE_ASYNC, { ...table, items }))
    yield put(actionObject(VALIDATE_PAYMENT_ASYNC, indexPayment))
    yield put(setError(null))
    yield put(setLoaderShow(false))
  } catch (error: any) {
    let message = error?.message
    console.log(error)
    yield put(setLoaderShow(false))
    if (error?.message?.includes('error')) {
      message = JSON.parse(message)?.error
      yield put(setError(message))
      return
    }
    yield put(setError('An error has ocurred, please contact support.'))

  }
}

export function* watchGetPaymentsTable() {
  yield takeLatest(GET_PAYMENTS_TABLE, getPaymentsTableAsync)
}

export function* watchValidatePayment() {
  yield takeLatest(VALIDATE_PAYMENT, validatePaymentAsync)
}
