import { CompanyPage, DashboardPage, Layout, PackagePage, PaymentPage, UserPage } from "@/components";
import React, { FC } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<DashboardPage />} />
      <Route path="/users" element={<UserPage />} />
      <Route path="/payments" element={<PaymentPage />} />
      <Route path="/companies" element={<CompanyPage />} />
      <Route path="/packages" element={<PackagePage />} />
    </Route>
  )
);
const PrivateRouter: FC = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default PrivateRouter
