import { AnyAction } from 'redux'
import { GET_PAYMENTS_ASYNC, GET_PAYMENTS_TABLE_ASYNC, VALIDATE_PAYMENT_ASYNC } from './action-types'

const initialState = {
  payments: [],
  payment: {},
  table: {},
  indexPayment: null,
}

const reducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case GET_PAYMENTS_ASYNC:
      return { ...state, payments: payload }
    case GET_PAYMENTS_TABLE_ASYNC:
      return { ...state, table: payload }
    case VALIDATE_PAYMENT_ASYNC:
      return { ...state, indexPayment: payload }
    default:
      return state
  }
}

export default reducer
