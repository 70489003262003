import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth/reducer'
import intermitence from './intermitence/reducer'
import user from './user/reducer'
import payment from './payment/reducer'
import company from './company/reducer'

const reducers: any = combineReducers({
  auth,
  intermitence,
  user,
  payment,
  company
})

export default reducers
