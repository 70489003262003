import { Container } from "@mui/material";
import { FC } from "react";

const DashboardPage: FC = () => {
  return (
    <>
      <Container component="main" sx={{ maxWidth: '100% !important' }} >
        <h2>DASHBOARD</h2>
        <p>Welcome back!</p>
      </Container>
    </>
  )
}

export default DashboardPage
