import { Login } from "@/pages";
import React, { FC } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
]);

const PublicRouter: FC = () => {
  return <RouterProvider router={routes} />
}

export default PublicRouter
